<template>
  <div class="mobilePage">
    <div class="title"></div>
    <div class="nav-box">
      <div
        class="telegram"
        @click.stop="toPage('https://t.me/liangnianbanlaosiji')"
      ></div>
      <div
        class="business"
        @click.stop="toPage('https://t.me/mu02guang')"
      ></div>
    </div>
    <div class="content">
      <div class="snake"></div>
      <div class="girl"></div>
      <div class="content-title"></div>
      <div class="content-subTitle"></div>
    </div>
    <div class="bottomBox">
      <img
        src="@/assets/images/mobile1/ios-btn.png"
        v-if="os.isPhone"
        alt=""
        @click="getApkInfo('ios')"
      />
      <img
        src="@/assets/images/mobile1/android-btn.png"
        @click="getApkInfo('android')"
        v-else
        alt=""
      />
      <div class="content-tip">
        提示：本APP属于特殊行业，偶尔会误报毒提醒，如有报毒提示，请点击忽略，并开启飞行模式安装即可！
      </div>
    </div>
    <!-- 安卓提示框 -->
    <van-overlay :show="androidPromptShow" z-index="999" class="androidOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="confirmBtnBox" @click="androidPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <!-- 苹果提示框 -->
    <van-overlay :show="iosPromptShow" z-index="999" class="iosOverlay">
      <div>
        <div class="overlayContent" @click.stop>
          <div class="appName">两年半</div>
          <div class="desc">全免费 更丰富 更高清</div>
          <div class="confirmBtnBox" @click="iosPromptShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <van-popup v-model="showTip" class="downTip">
      <div class="downTipTitle">温馨提示</div>
      <div class="downTipContent">
        请使用Safari浏览器进行下载
      </div>
      <div class="url">{{ currUrl }}</div>
      <div class="downTipConfirm" @click.stop="doCopy">复制链接</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["os"],
  data() {
    return {
      androidPromptShow: false,
      iosPromptShow: false,
      showTip: false,
      currUrl: window.location.href,
    };
  },
  mounted() {
    let ua = this.os.userAgent.toLowerCase();
    let isSafari =
      ua.indexOf("safari") !== -1 &&
      ua.indexOf("browser") === -1 &&
      ua.indexOf("android") === -1;
    if (!isSafari && this.os.isPhone) {
      this.showTip = true;
    }
    const referrer = sessionStorage.getItem("referrer");
    const dc = sessionStorage.getItem("dc");
    if (referrer && dc) {
      this.currUrl = referrer + "user.html?dc=" + dc;
    } else {
      if (referrer) {
        this.currUrl = referrer + "user.html";
      } else {
        this.currUrl = window.location.href;
      }
    }
  },
  methods: {
    // 剪贴板
    doCopy() {
      this.$copyText(this.currUrl).then(
        () => {
          console.log("复制成功");
          this.showTip = false;
        },
        () => {
          console.log("复制失败");
        }
      );
    },
    async showInstallTutorial(type) {
      if (type == "ios") {
        this.iosPromptShow = true;
      } else {
        this.androidPromptShow = true;
      }
    },
    async getApkInfo(type) {
      await this.showInstallTutorial(type);
      setTimeout(() => {
        this.$emit("getApkInfo", type);
      }, 500);
    },
    toPage(path) {
      window.open(path);
    },
  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

.mobilePage {
  height: 100vh;
  width: 100%;
  position: relative;
  background: url("./../../../assets/images/mobile1/bg.webp") no-repeat;
  background-size: cover;
  .title {
    position: absolute;
    top: 4px;
    left: 16px;
    width: 121.983px;
    height: 42px;
    background: url("../../../assets/images/mobile1/logo.png") no-repeat;
    background-size: cover;
  }
  .nav-box {
    position: absolute;
    right: 8px;
    top: 6px;
    z-index: 1000;
    display: flex;
    .telegram {
      background: url("../../../assets/images/mobile1/telegram.png") no-repeat;
      background-size: contain;
      width: 77.388px;
      height: 32.049px;
      margin-right: 12.22px;
    }
    .business {
      background: url("../../../assets/images/mobile1/business.png") no-repeat;
      background-size: contain;
      width: 77.388px;
      height: 32.049px;
    }
  }

  .content {
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    .snake {
      background: url("../../../assets/images/mobile1/snake.webp") no-repeat;
      background-size: contain;
      width: 359.278px;
      height: 343.027px;
      left: -137px;
      top: -174px;
      position: absolute;
    }
    .girl {
      background: url("../../../assets/images/mobile1/girl.webp") no-repeat;
      background-size: contain;
      width: 269.645px;
      height: 504.783px;
      left: -42px;
      top: -226px;
      position: absolute;
    }
    .content-title {
      background: url("../../../assets/images/mobile1/content-title.webp")
        no-repeat;
      background-size: contain;
      width: 398px;
      height: 206.868px;
      left: 50%;
      top: 44px;
      transform: translateX(-48%);
      position: absolute;
    }
    .content-subTitle {
      background: url("../../../assets/images/mobile1/content-subTitle.webp")
        no-repeat;
      background-size: contain;
      width: 342.747px;
      height: 40.813px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 240px;
    }
  }
  .bottomBox {
    position: fixed;
    bottom: 0px;
    width: 100%;
    left: 0px;
    text-align: center;
    z-index: 2000;
    img {
      width: 120px;
      height: 36px;
    }
    .content-tip {
      width: 291.127px;
      margin: 14px auto 20px;
      color: #520f14;
      text-align: center;
      font-family: "PingFang SC";
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .androidOverlay {
    display: flex;
    align-items: center;
    justify-content: center;

    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/android_tip.png")
        no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;

      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }

  .iosOverlay {
    display: flex;
    align-items: center;
    justify-content: center;

    .overlayContent {
      width: 297px;
      height: 441px;
      background: url("./../../../assets/images/mobile1/iosTip.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 12px;
      padding-top: 380px;
      position: relative;

      .appName {
        font-size: 10px;
        font-weight: 500;
        color: #242423;
        position: absolute;
        left: 79px;
        bottom: 102px;
        z-index: 1;
        background-color: #ffffff;
        width: 100px;
      }

      .desc {
        font-size: 7px;
        font-weight: 500;
        color: #8f908f;
        position: absolute;
        left: 79px;
        bottom: 92px;
        z-index: 1;
        background-color: #ffffff;
        width: 100px;
      }

      .confirmBtnBox {
        height: 36px;
        width: 140px;
        border-radius: 40px;
        margin: 0 auto;
      }
    }
  }
}

.downTip {
  border-radius: 10px;
  padding: 18px;
  box-sizing: border-box;
  text-align: center;

  .downTipTitle {
    font-size: 18px;
    margin-bottom: 34px;
  }

  .downTipContent {
    white-space: nowrap;
    font-size: 15px;
  }

  .url {
    font-size: 15px;
    margin-top: 16px;
    text-align: left;
  }

  .downTipConfirm {
    // margin-top: 12px;
    font-size: 15px;
    width: 160px;
    height: 30px;
    border-radius: 40px;
    margin: 32px auto 0;
    color: #fff;
    background: #5b92ee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
