<template>
  <div class="pcm-wrapper" id="pcm-wrapper">
    <div class="title"></div>
    <div class="navTo-box">
      <img
        class="tg"
        @click="toTg"
        src="@/assets/images/pc/telegram.webp"
        alt=""
      />
      <img
        class="potato"
        @click="toPotato"
        src="@/assets/images/pc/potato.webp"
        alt=""
      />
    </div>
    <div class="center">
      <div class="left"></div>
      <div class="right"></div>
      <div class="bottom-title"></div>
      <div class="bottom-subTitle"></div>
    </div>
    <div class="qrcode-box">
      <div class="qrBox">
        <img class="qrcodeBox" :src="qrImg" alt="" />
      </div>
      <div class="qrcode-title">安卓手机建议使用UC浏览器扫码</div>
      <div class="qrcode-tip">
        本平台主要收益为广告赞助，且保证APP安全无毒，
        因平台属于特殊行业，某些杀毒软件会误报毒提醒， 如遇此类提醒请点击忽略。
      </div>
    </div>
  </div>
</template>

<script>
import autofit from "autofit.js";
autofit.init();
export default {
  props: ["qrImg"],
  mounted() {
    autofit.init(
      {
        dh: 1080,
        dw: 1920,
        el: "#pcm-wrapper",
        resize: true,
      },
      false
    ); // 可关闭控制台运行提示输出
  },
  beforeDestroy() {},
  methods: {
    toTg() {
      window.open("https://t.me/liangnianbanlaosiji");
    },
    toPotato() {
      window.open("https://chptdl.org/lnbsq");
    },
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

.pcm-wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url("./../../../assets/images/pc/pc.webp") no-repeat center;
  background-size: cover;
  .title {
    position: absolute;
    left: 48px;
    top: 16px;
    width: 308px;
    height: 120px;
    background: url("../../../assets/images/pc/title-bg.webp") no-repeat;
    background-size: contain;
  }
  .navTo-box {
    display: flex;
    position: absolute;
    top: 27px;
    right: 133px;
    cursor: pointer;
    z-index: 1000;
    .tg {
      width: 198px;
      height: 82px;
      margin-right: 30px;
    }
    .potato {
      width: 198px;
      height: 82px;
    }
  }
  .center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1361px;
    height: 100%;
    .left {
      background: url("../../../assets/images/pc/snake.webp") no-repeat;
      background-size: contain;
      width: 619px;
      height: 591px;
      position: absolute;
      top: 118px;
      left: 288px;
      z-index: 10;
    }
    .right {
      background: url("../../../assets/images/pc/girl.webp") no-repeat;
      background-size: contain;
      width: 448.578px;
      height: 839.75px;
      position: absolute;
      top: 39.65px;
      right: 325.87px;
      z-index: 11;
    }
    .bottom-title {
      width: 1361px;
      height: 471px;
      background: url("../../../assets/images/pc/bottom-title.webp") no-repeat;
      background-size: contain;
      position: absolute;
      top: 540px;
      left: 0;
      z-index: 12;
    }
    .bottom-subTitle {
      width: 823px;
      height: 98px;
      background: url("../../../assets/images/pc/bottom-subTitle.webp")
        no-repeat;
      background-size: contain;
      position: absolute;
      top: 941px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 13;
    }
  }
  .qrcode-box {
    align-items: center;
    position: absolute;
    bottom: 55.87px;
    left: 0px;
    .qrBox {
      width: 244px;
      height: 298px;
      background: url("./../../../assets/images/pc/codebg.webp") no-repeat;
      background-size: contain;
      position: relative;
      .qrcodeBox {
        width: 244px;
        height: 244px;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .qrcode-title {
      color: #041345;
      font-family: "Advent Pro SemiBold";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      letter-spacing: 0.12px;
      padding: 22px 0 4px;
      margin-left: 4px;
    }
    .qrcode-tip {
      padding-top: 16px;
      margin-left: 4px;
      border-top: 2px solid #520f14;
      color: #041345;
      font-family: "Alibaba PuHuiTi";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 408px;
    }
  }
}
</style>
